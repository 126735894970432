/**
 * 隔三位打点，不处理小数点的情况
 * @param {String} moneyStr 
 * @returns 
 */
export default function (moneyStr) {
    let res;

    moneyStr = typeof moneyStr === 'number' ? String(moneyStr) : moneyStr;
    res = moneyStr.replace(/(\d)(?=(?:\d{3})+$)/g, '$1.');

    return res;
}