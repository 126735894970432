import storage from '@/utils/storage'

const addAuth = (config) => {
  let token = storage.get('token')

  //已登录
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

export default addAuth
