<template>
  <div class="item" @click="gotoDetails">
    <!-- 每日推荐活动 -->
    <template v-if="isDaily">
      <div class="top">HARGA TERENDAH PADA PUKUL 20:00 SETIAP HARI</div>
      <div class="img m-t-10" :style="{backgroundImage:goodsThumb}">
        <div class="tips" v-if="rate">-{{rate}}</div>
        <div class="mask" v-if="dailyStatus == 1"></div>
      </div>
      <div class="good-title">{{info.coupon_title}}</div>
      <div class="desc">{{info.coupon_desc}}</div>
      <div class="price fx-row fx-v-center">
          Rp{{formatSplitMoney(info.coupon_price)}}
        <div class="origin" v-if="info.origin_price">Rp{{formatSplitMoney(info.origin_price)}}</div>
      </div>

      <!-- 去拼团 -->
      <div class="btn" v-if="dailyStatus == 0"
           @click.stop="onStartNewGroup">
        GO PEMBELIAN PANIK
      </div>

      <!--- 每日推荐活动还未开始 -->
      <div class="bottom fx-col" v-else>
        <div class="up fx-row fx-v-center">
          <template v-if="dailyStatus == -1 && dailyRemain >= 0">
            <div class="count-down fx-1 fx-row fx-v-center fx-m-between">
              <div class="txt fx-1">PEMBELIAN<br/>SETELAH</div>
              <times type="min-gray" :remain="dailyRemain" @over="onOver"></times>
            </div>
          </template>

          <template v-else>
            <div class="txt fx-1">
              UPDATE BESOK JAM 20:00
            </div>
          </template>
        </div>
        <!-- 发通知 -->
        <div class="down fx-1" @click.stop="dailySubscribe(id)">
          INGATKAN SAYA
        </div>
      </div>
    </template>

    <template v-else>
      <div class="img" :style="{backgroundImage:goodsThumb}"></div>
      <div class="good-title">{{info.coupon_title}}</div>
      <div class="desc">{{info.coupon_desc}}</div>
      <div class="price fx-row fx-v-center">
          Rp{{formatSplitMoney(info.coupon_price)}}
        <div class="origin" v-if="info.origin_price">Rp{{formatSplitMoney(info.origin_price)}}</div>
      </div>
      <div class="info">Telah diterima {{totalSucc}}</div>
    </template>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../filters/formatBackgroundImgUrl'
  import formatDiscountRate from '../filters/formatDiscountRate'
  import formatSplitMoney from '../filters/formatSplitMoney'
  //mixin
  import gotoPage from '../mixins/gotoPage'
  import groupHandler from '../mixins/groupHandler'
  //component
  import Times from '../components/times'

  let lock = false

  export default {
    props: ['info'],
    mixins: [
      gotoPage,
      groupHandler
    ],
    components: {
      Times
    },
    computed: {
      id() {
        return this.info && this.info.id || ''
      },
      //是否日推
      isDaily() {
        return this.info && this.info.category == 4 || false
      },
      //日推活动状态0进行中 -1未开始 1售完
      dailyStatus() {
        let status = -1

        if (this.isDaily) {
          //倒计时状态
          if (this.dailyRemain >= 0) {
            status = -1
          }
          //进行中
          else if (this.dailyRemain < 0 && this.info.status == 0) {
            status = 0
          }
          //售磬
          else {
            status = 1
          }
        }

        return status
      },
      //日推活动倒计时
      dailyRemain() {
        let remain = -1

        if (this.isDaily) {
          let current = this.info.current_time
          let start = this.info.start_time
          remain = start - current
        }

        return remain
      },
      goodsThumb() {
        let img = this.info && this.info.goods_thumbnail || ''
        return formatBackgroundImgUrl(img)
      },
      rate() {
        let rate = this.info && this.info.discount_rate || ''
        return rate && formatDiscountRate(rate) || ''
      },
      totalSucc() {
        return this.info && this.info.total_succ_group || 100
      }
    },
    methods: {
      formatSplitMoney,
      gotoDetails() {
        this.gotoPage('details', {id: this.info.id})
      },
      onStartNewGroup() {
        if (lock) {
          return
        }
        lock = true

        this.startNewGroup(1).then(() => {
          lock = false
        })
      },
      onOver() {
        this.$emit('fresh')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    width: pxTo(165);
    margin-bottom: pxTo(24);

    .top {
      width: pxTo(165);
      height: pxTo(50);
      text-align: center;
      padding: pxTo(8) pxTo(6) 0 pxTo(6);

      @extend %ff-rr;
      @include fs-l-w-c(11, 14, 400, $c2);
      @include bg($img-base, 'good-top.png');
      background-size: 100% 100%;
    }

    .img {
      width: pxTo(165);
      height: pxTo(165);
      background-color: #F4F4F4 !important;
      background-repeat: no-repeat !important;
      background-size: 100% auto !important;
      background-position: center center !important;
      border-radius: pxTo(8);
      position: relative;

      &.m-t-10 {
        margin-top: pxTo(-6);
      }

      .tips {
        width: pxTo(38);
        height: pxTo(20);
        background-color: rgba(146, 108, 255, 0.9);
        text-align: center;
        position: absolute;
        top: pxTo(12);
        right: pxTo(12);

        @extend %ff-rr;
        @include fs-l-w-c(11, 20, 400, $c2);
      }

      .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;

        @include bg($img-base, 'good-mask.png');
      }
    }

    .good-title {
      margin-top: pxTo(9);
      height: pxTo(32);
      word-wrap:break-word;
      word-break: normal !important; 

      @extend %break;
      @extend %ff-rm;
      @include fs-l-w-c(14, 16, 500, $c9);
      @include m-ellipsis(2);
    }

    .desc {
      margin-top: pxTo(3);
      padding-right: pxTo(5);

      // @extend %break;
      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);
      @include m-ellipsis(2);
    }

    .price {
      margin-top: pxTo(8);

      @extend %ff-rm;
      @include fs-l-w-c(16, 16, 500, $c16);

      .origin {
        text-decoration: line-through;
        margin-left: pxTo(10);

        @include fs-l-w-c(10, 10, 400, $c20);
      }
    }

    .info {
      display: inline-block;
      height: pxTo(16);
      background: #F4F4F4;
      border-left: 2px solid #E1E1E4;
      border-radius: 0 pxTo(10) pxTo(10) 0;
      padding: 0 pxTo(6);

      @extend %ff-rr;
      @include fs-l-w-c(9, 16, 400, #B8B6C1);
    }

    .bottom {
      width: 100%;
      height: pxTo(54);
      margin-top: pxTo(9);

      @include bg($img-base, 'good-bottom.png');
      background-size: 100% 100%;

      .up {
        height: pxTo(22);
        padding: 0 pxTo(6);

        .txt {
          text-align: center;

          @include fs-l-w-c(10, 10, 400, $c21);
        }
      }

      .down {
        text-align: center;

        @extend %ff-rm;
        @include fs-l-w-c(14, 32, 900, $c2);
      }
    }

    .btn {
      width: 100%;
      height: pxTo(32);
      text-align: center;
      background: $bg1;
      border-radius: pxTo(6);
      margin-top: pxTo(15);

      @extend %ff-rm;
      @include fs-l-w-c(14, 32, 900, $c2);
    }
  }
</style>