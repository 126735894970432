<template>
  <div class="new-user box"
       v-if="list">
    <div class="title fx-row fx-v-center">
      <div class="fx-1">Komoditas panas</div>
    </div>
    <van-swipe class="my-swipe"
               :autoplay="3000"
               indicator-color="white">
      <van-swipe-item v-for="(i,index) in list"
                      :key="index">
        <div class="coupon fx-row"
             @click="gotoDetials(i.id)">
          <div class="img"
               :style="{background:`url(${i.goods_thumbnail})`}"></div>
          <div class="info fx-1">
            <div class="name">{{i.coupon_title}}</div>
            <div class="desc">{{i.coupon_desc}}</div>
            <div class="price">
              <div class="new">Rp{{formatSplitMoney(i.coupon_price)}}</div>
              <div class="old">Rp{{formatSplitMoney(i.origin_price)}}</div>
            </div>
            <div class="btn-rainbow"
                 @click.stop="gotoDetials(i.id)">
              <div>PERGI UNTUK JOIN</div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <!-- <div class="coupon fx-row"
         @click="gotoDetials">
      <div class="img"
           :style="{backgroundImage:goodImg}"></div>
      <div class="info fx-1">
        <div class="name">{{info.coupon_title}}</div>
        <div class="desc">{{info.coupon_desc}}</div>
        <div class="price">
          <div class="new">Rp0</div>
          <div class="old">Rp{{formatSplitMoney(info.origin_price)}}</div>
        </div>
        <div class="btn-rainbow"
             @click.stop="gotoDetials">
          <div>PERGI UNTUK JOIN</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import formatSplitMoney from '../../../filters/formatSplitMoney'
//mixin
import groupHandler from '../../../mixins/groupHandler'
import gotoPage from '../../../mixins/gotoPage'
import service from '../../../service/index'

export default {
  mixins: [
    groupHandler,
    gotoPage
  ],
  data () {
    return {
      list: []
    }
  },
  methods: {
    formatSplitMoney,
    init () {
      service.getGoods({
        page_size: 100,
        // status: -1,
        page: 1,
        category: 39
      })
        .then(res => {
          this.list = res.activities
        })
    },
    gotoDetials (id) {
      this.gotoPage('details', { id, })
    },
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.new-user {
  margin-top: 0 !important;

  .coupon {
    padding: pxTo(9) pxTo(16) pxTo(12) pxTo(16);
    background-color: $c2;

    .img {
      width: pxTo(110);
      height: pxTo(110);
      border-radius: pxTo(8);
      overflow: hidden;
      background-color: #dcd5ff !important;
      background-repeat: no-repeat !important;
      background-size: 100% auto !important;
      background-position: center center !important;
    }

    .info {
      margin-left: pxTo(12);
      position: relative;
      .name {
        @extend %ff-rm;
        @include fs-l-w-c(16, 19, 500, $c9);
        @include m-ellipsis(2);
      }

      .desc {
        margin-top: pxTo(6);

        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
        @include m-ellipsis(2);
      }

      .price {
        position: absolute;
        left: 0;
        bottom: pxTo(4);

        .new,
        .old {
          @extend %no-break;
        }

        .new {
          @extend %ff-rm;
          @include fs-l-w-c(16, 19, 500, $c16);
        }

        .old {
          text-decoration: line-through;

          @extend %ff-rr;
          @include fs-l-w-c(10, 11, 400, $c12);
        }
      }

      .btn-rainbow {
        width: pxTo(114);
        height: pxTo(26);
        position: absolute;
        right: 0;
        bottom: 0;

        > div {
          font-size: pxTo(10);
          line-height: pxTo(24);
        }
      }
    }
  }
}
</style>