<template>
  <div class="times fx-row fx-nowrap" :class="timesStyle" v-if="times">
    <div class="fx-1">{{times.h}}</div>
    <div class="fx-1">{{times.m}}</div>
    <div class="fx-1">{{times.s}}</div>
    <div class="fx-1">{{ms}}</div>
  </div>
</template>

<script>
  import countDownHandler from '../mixins/countDownHandler'

  export default {
    props: ['type', 'onlyShow'],
    data() {
      return {}
    },
    mixins: [
      countDownHandler
    ],
    computed: {
      timesStyle() {
        return this.type || 'min'
      }
    },
    watch: {
      remain() {
        this.init()
      },
      during() {
        this.init()
      }
    },
    methods: {
      init() {
        //仅展示
        if (this.onlyShow) {
          this.cleanTimer()
          this.times = this.duringTimes()
          this.ms = '00'
        } else {
          //初始化倒计时
          this.initCountDown()
        }
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .times {
    //小倒计时
    &.min, &.min-gray {
      width: pxTo(16*4+2*3);

      &.over {
        > div {
          background-color: $c12;
        }
      }

      > div {
        width: pxTo(16);
        height: pxTo(13);
        border-radius: pxTo(2);
        background-color: $c16;
        text-align: center;
        margin-left: pxTo(2);

        @extend %ff-rb;
        @include fs-l-w-c(10, 13, 700, $c2);
      }
    }

    &.min-gray {
      > div {
        background-color: $c12;
      }
    }

    //大倒计时
    &.max {
      width: pxTo(156);
      height: pxTo(25);

      @include bg($img-base, 'ui_counter_large_normalnull.svg');

      &.over {
        @include bg($img-base, 'ui_counter_large_disable.svg');
      }

      > div {
        text-align: center;
        height: pxTo(25);
        margin-right: pxTo(12);

        @extend %ff-rb;
        @include fs-l-w-c(16, 25, 700, $c2);

        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
    }
  }
</style>