const parseResponse = function ({data = {}} = {}) {
  let result

  //请求成功
  if (data && data.code == 2000) {
    result = Promise.resolve(data.data)
  } else {
    result = Promise.reject({code:data && data.code || 0, message: data && data.message || 'network error'})
  }

  return result
}

export default parseResponse
