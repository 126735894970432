export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $showLoading() {
          this.$bus.$emit('loading', true)
        },
        $hideLoading() {
          this.$bus.$emit('loading', false)
        }
      }
    })
  }
}