let localStorage;

function pollyfillLocalStorage() {
  let data = {};

  return {
    setItem(key, val) {
      data[key] = String(val);
    },
    getItem(key) {
      /* eslint-disable-next-line */
      return data.hasOwnProperty(key) ? data[key] : null;
    },
    removeItem(key) {
      delete data[key];
    },
    clear() {
      data = {};
    }
  };
}

// If user disables cookie in Safari, directly access to window.localStorage
// will cause an error, so we must check navigator.cookieEnabled first.

if (navigator.cookieEnabled && window.localStorage) {
  localStorage = window.localStorage;
} else {
  localStorage = pollyfillLocalStorage();
}

export default {
  set(key, val) {
    return localStorage.setItem(key, JSON.stringify(val));
  },
  get(key) {
    const val = localStorage.getItem(key);
    if (val === null) return null;
    if (val === undefined) return undefined;
    return JSON.parse(val);
  },
  remove(key) {
    return localStorage.removeItem(key);
  },
  clear() {
    return localStorage.clear();
  }
};
