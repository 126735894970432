<template>
  <div class="fx-center popup-mask" v-if="isShow" @click="onClose">
    <div class="notify pupup-center fx-c-center" @click.stop="">
      <div class="desc">
        Pengingat telah berhasil disetel dan akan<br/>mengingatkan Anda 5 menit sebelum memulai
      </div>
      <div class="content">
        <!-- 发起新拼团 -->
        <div class="btn" @click="onClose">OKE</div>
      </div>
    </div>
  </div>
</template>

<script>
  import groupHandler from '../mixins/groupHandler'
  import gotoPage from '../mixins/gotoPage'

  export default {
    data() {
      return {
        isShow: false
      }
    },
    mixins: [
      groupHandler,
      gotoPage
    ],
    methods: {
      setShow() {
        this.isShow = true

        window.track('wowsaa-groups', 'notice', 'popup')
      },
      onClose() {
        this.isShow = false
      }
    },
    created() {
      this.$bus.$off('notify', this.setShow);
      this.$bus.$on('notify', this.setShow);
    }
  }
</script>

<style lang="scss" scoped>
  .popup-mask {
    background-color: rgba(0, 0, 0, .8);
  }

  .notify {
    width: pxTo(320);
    height: pxTo(160);
    background-color: $c2;
    position: relative;
    border-radius: pxTo(16);
    padding-top: pxTo(42);

    &::before {
      content: '';
      display: block;
      width: pxTo(70);
      height: pxTo(70);
      top: pxTo(-30);

      @include bg($img-base, 'clock.png');
      @extend %h-center;
    }

    .desc {
      text-align: center;
      margin-top: pxTo(3);

      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);
    }

    .content {
      margin-top: pxTo(10);
      padding-top: pxTo(16);

      .sub-title {
        text-align: center;

        @extend %ff-rr;
        @include fs-l-w-c(11, 13, 400, $c12);
      }

      .coupons {
        width: 100%;
        height: pxTo(144);
        margin-top: pxTo(8);
        position: relative;
        padding: pxTo(10) 0 pxTo(20) 0;

        @include bg($img-base, 'coupons-bg.png');
        background-size: 100% 100%;

        .left {
          width: pxTo(140);
          height: 90%;

          &.free {
            @include bg($img-base, 'free.png');
            background-size: pxTo(97) pxTo(30);
          }

          &.discount {
            @include bg($img-base, 'discount.png');
            background-size: pxTo(73) pxTo(46);
          }
        }

        .right {
          margin-left: pxTo(-8);
          padding-right: pxTo(20+12);

          .title {
            text-align: left;
            margin-bottom: pxTo(8);

            @extend %ff-rm;
            @include fs-l-w-c(14, 16, 500, $c9);
          }

          .desc, .price {
            text-align: left;

            @extend %ff-rr;
            @include fs-l-w-c(12, 14, 400, $c10);
          }
        }
      }

      .btn {
        margin: 0 pxTo(20);
        background: $bg1;
        height: pxTo(42);
        text-align: center;
        border-radius: pxTo(6);

        @extend %ff-rb;
        @include fs-l-w-c(14, 42, 900, $c2);
      }
    }
  }
</style>