//api
import service from '../service'

//查看用户参与+发起信息
function checkMyJoinInfo(activityId) {
  return service.getUserGroupStatus(activityId)
}

/*function gotoOnline() {
  location.href = 'https://wowsaa.myshopify.com/pages/contact-us-v2'
}*/

export default {
  methods: {
    getUserGroupStatus(activityId) {
      return checkMyJoinInfo(activityId)
    },
    joinById(id, activityId, fresh = false) {
      return this.$getAuth().then(() => {
        this.$showLoading()
        //已登录
        return this.$join(id, activityId, fresh)
      }).catch(() => {
        //未登录
        this.$bus.$emit('login', {
          callback: () => {
            this.$join(id, activityId, fresh)
          }
        })
      })
    },
    //参与
    /*$join() {
      //下线
      gotoOnline()

      return Promise.resolve().then(this.$hideLoading)
    },*/
    $join(id, activityId, fresh) {
      this.$showLoading()

      return checkMyJoinInfo(activityId)
        .then(({
                 join_group_num = 0,
                 max_join_group_per_user = 0
               }) => {
          let result = null

          //可以参加
          if (!max_join_group_per_user || (join_group_num < max_join_group_per_user)) {

            result = service.joinById(id).then(() => {
              window.track('wowsaa-groups', `join`, 'click')
              window.track('wowsaa-groups', `join-${activityId}`, 'click')
              //刷新当前页
              if (fresh) {
                this.$emit('fresh')
              } else {
                this.$hideLoading()
                //跳转到邀请页
                this.gotoPage('invite', {id})
              }
            })
          } else {
            //不能参加，弹窗提示
            this.$bus.$emit('cry')
          }

          return result
        }).catch(this.$apiErrorHandler).then(this.$hideLoading)
    },
    startNewGroup(activityId) {
      return this.$getAuth().then(() => {
        this.$showLoading()
        //已登录
        return this.$start(activityId)
      }).catch(() => {
        //未登录
        this.$bus.$emit('login', {
          callback: () => {
            this.$start(activityId)
          }
        })
      })
    },
    //发起
    /*$start() {
      //下线
      gotoOnline()

      return Promise.resolve().then(this.$hideLoading)
    }*/
    $start(activityId) {
      return checkMyJoinInfo(activityId)
        .then(({
                 create_group_num = 0,
                 max_create_group_per_user = 0
               }) => {
          let result = null
          
          //可以发起
          if (!max_create_group_per_user || (create_group_num < max_create_group_per_user)) {

            result = service.startNewGroup(activityId)
            .then((data) => {
              window.track('wowsaa-groups', `start`, 'click')
              window.track('wowsaa-groups', `start-${activityId}`, 'click')
              this.gotoPage('invite', {id: data.id})
            }).catch(this.$apiErrorHandler)
          } else {
            //不能发起，弹窗提示
            this.$bus.$emit('cry')
          }

          return result
        }).catch(this.$apiErrorHandler).then(this.$hideLoading)
    },
    //订阅每日活动
    dailySubscribe(activityId) {
      return this.$getAuth().then(() => {
        this.$showLoading()
        //已登录
        return this.$subscribe(activityId)
      }).catch(() => {
        //未登录
        this.$bus.$emit('login', {
          callback: () => {
            this.$subscribe(activityId)
          }
        })
      })
    },
    $subscribe(activityId) {

      return service.subscribeDailyActivity(activityId).then(() => {
        window.track('wowsaa-groups', 'notify', 'click')
        this.$bus.$emit('notify')
        this.$hideLoading()
      }).catch(this.$apiErrorHandler)
    }
  }
}