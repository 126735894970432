import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'

//路由
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(l) {
  return originalPush.call(this, l).catch(() => {
    if (l && l.path && location.href.indexOf(l.path) >= 0) {
      location.reload()
    }
  })
}

export default {Vue, router}