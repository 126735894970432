export default function (target, shareUrl, title = 'wowsaa') {
  let url = ''

  switch (target) {
    case "facebook":
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&t=${encodeURIComponent(title)}`;
      break
    case "twitter":
      url = `https://twitter.com/share?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`;
      break
    case "instagram":
      url = `https://www.instagram.com/aiaindonesia/?hl=en`;
      break
    case "whatsapp":
      url = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' ' + shareUrl)}`
      break
    default:
      break
  }

  return url
}