<template>
  <div class="home">
    <div class="banner">
      <div class="intro" @click="gotoIntro"></div>
    </div>

    <new-user-coupon></new-user-coupon>

    <div class="goods box">
      <div class="title fx-row fx-v-center">
        <div class="fx-1">Produk Hot</div>
      </div>

      <div class="goods-list fx-row fx-c-baseline" v-if="goods">
        <div>
          <div v-for="(item, index) of pullWaterLeftData" :key="index">
            <good-item :info="item" @fresh="fresh"></good-item>
          </div>
        </div>
        <div>
          <good-item
            v-for="(item, index) of pullWaterRightData"
            :key="index"
            :info="item"
          ></good-item>
        </div>
      </div>
    </div>

    <div class="end">- Tidak ada produk lagi -</div>

    <div class="iphone-x"></div>

    <bottom isactive="1"></bottom>
  </div>
</template>

<script>
//mixin
import gotoPage from "../../mixins/gotoPage";
//component
import NewUserCoupon from "./components/newUserCoupon";
import GoodItem from "../../components/goodItem";
import Bottom from "../../components/bottom";
//api
import service from "../../service";

export default {
  name: "groupsHome",
  data() {
    return {
      newUserGood: false,
      goods: false,         // 列表数据
      params: {
        category: 38,
      },
      pullWaterLeftData: [],
      pullWaterRightData: []
    };
  },
  mixins: [gotoPage],
  components: {
    NewUserCoupon,
    GoodItem,
    Bottom,
  },
  watch: {
    $route() {
      //滚到指定位置
      if (
        this.$route.path.indexOf("groups/home") >= 0 &&
        this.$route.query.anchor
      ) {
        this.$nextTick(this.gotoAnchor);
      }
    },
  },
  methods: {
    init() {
      this.getGoods()
        .then(this.$hideLoading)
        .then(() => {
          //滚到指定位置
          if (this.$route.query.anchor) {
            this.$nextTick(this.gotoAnchor);
          }
        });
    },
    fresh() {
      this.$showLoading();
      this.init();
    },
    getGoods() {
      this.$showLoading();
      return service.getGoods(this.params)
        .then((data) => {
            this.goods = data;
            this.pullWaterLeftData = data.activities.filter((item,index)=>index % 2 === 0);
            this.pullWaterRightData = data.activities.filter((item,index)=>index % 2 === 1);
        })
        .catch(() => {
          this.goods = [];
        });
    },
    gotoIntro() {
      this.gotoPage("intro");
    },
  },
  mounted () {
    this.init()
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: $c11;
  padding-bottom: pxTo(84);

  @extend %page;
}

.banner {
  width: 100%;
  height: pxTo(93);
  position: relative;

  @include bg($img-base, "banner.jpg");
  background-size: 100% auto;
  background-position: top center;

  .intro {
    width: pxTo(100);
    height: pxTo(18);
    position: absolute;
    right: pxTo(6);
    top: pxTo(9);
    opacity: 0;
  }
}

.tabs-box {
  height: pxTo(33);
  background-color: $c2;
}

.goods-list {
  padding: pxTo(12) pxTo(16) pxTo(1) pxTo(16);

  > div {
    width: pxTo(165);

    &:nth-last-of-type(1) {
      margin-left: pxTo(11);
    }
  }
}

.end {
  text-align: center;
  margin-top: pxTo(12);

  @extend %ff-rr;
  @include fs-l-w-c(12, 14, 400, $c10);
}

.seckill-good {
  width: pxTo(165);
  height: pxTo(300);
  background-color: red;
  margin-bottom: pxTo(24);
  display: inline-block;
}
</style>
