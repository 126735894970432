export default {
  //剩余秒数/历时
  props: ['remain', 'during'],
  data() {
    return {
      timer: false,
      //毫秒倒计时
      sstimer: false,
      times: false,
      //毫秒
      ms: false
    }
  },
  watch: {
    end() {
      this.initCountDown()
    }
  },
  methods: {
    initCountDown() {
      this.cleanTimer()
      this.startCountDownTimer()
    },
    startCountDownTimer() {
      let difference = Number(this.remain)

      //需要倒计时
      if (difference >= 0) {
        this.times = countDown(difference)
        this.ms = '09'

        //启动倒计时
        this.timer = setInterval(() => {
          let result = countDown(difference)
          difference = result.difference

          //结束倒计时
          if (difference < 0) {
            this.cleanTimer()
            this.ms = '00'

            //倒计时结束
            this.$emit('over')
          } else {
            this.times = {h: result.h, m: result.m, s: result.s}
          }
        }, 1000)

        this.sstimer = setInterval(() => {
          this.ms = countDownSs(this.ms)
        }, 100)
      } else {
        this.times = {h: '00', m: '00', s: '00'}
        this.ms = '00'
      }
    },
    duringTimes() {
      return secondToDate(this.during)
    },
    cleanTimer() {
      this.timer = cleanTimer(this.timer)
      this.sstimer = cleanTimer(this.sstimer)
    }
  },
  beforeDestroy() {
    this.cleanTimer()
  }
}

//清理定时器
function cleanTimer(timer) {
  timer && clearInterval(timer)

  return false
}

function countDown(difference) {
  let result
  //倒计时减1s
  difference -= 1
  result = secondToDate(difference)
  result.difference = difference

  return result
}

function countDownSs(ss) {
  ss = Number(ss)
  ss -= 1

  if (ss < 0) {
    ss = 9
  }

  return `0${ss}`
}

function secondToDate(seconds) {
  let h = '00'
  let m = '00'
  let s = '00'

  if (seconds > 0) {
    h = Math.floor(seconds / 3600)
    m = Math.floor(seconds / 60 % 60)
    s = Math.floor(seconds % 60)

    if (h < 10) {
      h = `0${h}`
    }

    if (m < 10) {
      m = `0${m}`
    }

    if (s < 10) {
      s = `0${s}`
    }
  }

  return {h, m, s}
}
