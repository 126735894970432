<template>
  <div class="container" v-show="isShow">
    <div class="loading">
      <img src="~@/assets/spinner.png" class="spinner"/>
    </div>
  </div>
</template>

<script>
  let timer

  export default {
    data() {
      return {
        isShow: false
      }
    },
    methods: {
      setShow(val) {
        clearTimer()

        if (!val) {
          timer = setTimeout(() => {
            this.isShow = val
          }, 300)
        } else {
          this.isShow = val
        }
      }
    },
    created() {
      this.$bus.$off('loading', this.setShow);
      this.$bus.$on('loading', this.setShow);
    },
    beforeDestroy() {
      clearTimer()
    }
  }

  function clearTimer() {
    timer && clearTimeout(timer)
    timer = null
  }
</script>

<style lang="scss" scoped>
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .container {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
  }

  .loading {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .spinner {
    display: block;
    width: 30px;
    height: 30px;
    margin: 10px auto 0 auto;
    animation: spin 2s linear infinite;
  }
</style>