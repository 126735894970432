import axios from 'axios'
import buildUrl from './interceptors/build-url'
import addAuth from './interceptors/add-auth'
import parseResponse from './interceptors/parse-response'

const request = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

request.interceptors.request.use(function (config) {
  return [buildUrl, addAuth].reduce((accumulator, currentValue) => {
    return currentValue(accumulator)
  }, config)
}, function (err) {
  return Promise.reject(err)
})

request.interceptors.response.use(function (response) {
  return [parseResponse].reduce((accumulator, currentValue) => {
    return currentValue(accumulator);
  }, response);
}, function (err) {
  return Promise.reject((err && err.response && err.response.data) || err);
});

export default request;