import app from '@/app'
import App from './App.vue'
import './router'
import bus from './bus'
import auth from './mixins/auth'
import gotoPage from './mixins/gotoPage'
import apiErrorHandler from './mixins/apiErrorHandler'
import ui from './mixins/ui'
import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
app.Vue.config.productionTip = false
app.Vue.use(bus)
app.Vue.use(Vant)
app.Vue.use(auth)
app.Vue.use(gotoPage)
app.Vue.use(apiErrorHandler)
app.Vue.use(ui)
app.Vue.use(Vant)
Vue.use(Vant);

new app.Vue({
  mixins: [],
  router: app.router,
  render: h => h(App),
}).$mount('#app')