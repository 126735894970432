<template>
  <div class="bottom iphone-x">
    <div class="fx-row">
      <div class="shop fx-1" :class="{ isactive: isactive == 1 }" @click="gotoHome">
        HOME
      </div>
      <div
        class="category fx-1"
        :class="{ isactive: isactive == 2}"
        @click="gotoCategory"
      >
        KATEGORI
      </div>
      <div class="group fx-1" :class="{ isactive: isactive == 3 }" @click="gotoMy">
        GRUP SAYA
      </div>
      <div class="mine fx-1" :class="{ isactive: isactive == 4 }" @click="gotomine">
        PRIBADI
      </div>
    </div>
  </div>
</template>

<script>
import gotoPage from "../mixins/gotoPage";

export default {
  props: ["isactive"],
  mixins: [gotoPage],
  data(){
    return{
    //isactive:0,
    }
  },
  methods: {
    gotoHome() {
      if (this.isactive == 1) {
        return;
      }

      return this.gotoPage("home");
    },
    gotoCategory() {
      if (this.isactive == 2) {
        return;
      }

      return this.gotoPage("category");
    },
     gotoMy() {
      if (this.isactive == 3) {
        return;
      }

      return this.$getAuth()
        .then(() => {
          //已登录
          return this.gotoPage("my");
        })
        .catch(() => {
          //未登录
          this.$bus.$emit("login", {
            callback: () => {
              this.gotoPage("my");
            },
          });
        });
    },
    gotomine() {
      if (this.isactive == 4) {
        return;
      }

      return this.gotoPage("mine");
    },
   
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  width: 100%;
  background-color: $c2;
  position: fixed;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  bottom: 0;
  z-index: 10;

  > div {
    height: pxTo(48);

    .shop,
    .group,
    .mine,
    .category {
      text-align: center;
      padding-top: pxTo(30);
      background-size: pxTo(21) pxTo(21) !important;
      background-position: center pxTo(6) !important;

      @extend %ff-rr;
      @include fs-l-w-c(9, 10, 400, #d8d8d8);
    }

    .shop {
      @include bg($img-base, "shop.png");

      &.isactive {
        color: $c5;

        @include bg($img-base, "shop-active.png");
      }
    }
    .category {
      @include bg($img-base, "fenlei1.png");

      &.isactive {
        color: $c5;

        @include bg($img-base, "fenlei3.png");
      }
    }
    .group {
      @include bg($img-base, "p4.png");
      &.isactive {
        color: $c5;
        @include bg($img-base, "p3.png");
      }
    }
    .mine {
      @include bg($img-base, "my.png");

      &.isactive {
        color: $c5;
        @include bg($img-base, "my-active.png");
      }
    }
  }
}
</style>