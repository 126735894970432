import app from '@/app'
import Home from './pages/home'

const Details = () => import('./pages/details')
const Comments = () => import('./pages/comments')
const Invite = () => import('./pages/invite')
const My = () => import('./pages/my')
const category = () => import('./pages/category')
const Intro = () => import('./pages/intro')
const mine = () => import('./pages/mine/mineinfo.vue')
const abouts = () => import('./pages/mine/abouts.vue')
const survey = () => import('./pages/mine/survey.vue')
const contackus = () => import('./pages/mine/contackus.vue')
const orders = () => import('./pages/mine/orders/orders.vue')
const orderDetail = ()=> import('./pages/mine/orders/orderDetail.vue')
const location = () => import('./pages/mine/location/index.vue')
const routes = [
  {
    path: '/groups/mine/orders',
    name: 'groups/mine/orders',
    component: orders,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine/orderdetail',
    name: 'groups/mine/orderDetail',
    component: orderDetail,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine/location',
    name: 'groups/mine/location',
    component: location,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/category',
    name: 'groups/category',
    component: category,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine/contackus',
    name: 'groups/mine/contackus',
    component: contackus,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine/survey',
    name: 'groups/mine/survey',
    component: survey,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine/abouts',
    name: 'groups/mine/abouts',
    component: abouts,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/mine',
    name: 'groups/mine',
    component: mine,
    meta: {
      title: ''
    }
  },
  {
    path: '/groups/intro',
    name: 'groups/intro',
    component: Intro,
    meta: {
      titile: ''
    }
  },
  {
    path: '/groups/my',
    name: 'groups/my',
    component: My,
    meta: {
      title: ''
    }
  },
  {
    path: '/groups/invite',
    name: 'groups/invite',
    component: Invite,
    meta: {
      title: ''
    }
  },
  {
    path: '/groups/comments',
    name: 'groups/comments',
    component: Comments,
    meta: {
      title: ''
    }
  },
  {
    path: '/groups/details',
    name: 'groups/details',
    component: Details,
    meta: {
      title: ''
    }
  },
  {
    path: '/*',
    name: 'groups/home',
    component: Home,
    meta: {
      title: 'WOWSAA'
    }
  },
]

window.wowsaaHistory = []
app.router.beforeEach((to, from, next) => {
  window.wowsaaHistory.push(to.fullPath)
  next()
})

app.router.addRoutes(routes)
