import VueRouter from 'vue-router'

const base = '/'

const router = new VueRouter({
  mode: 'history',
  base: base,
  routes: [],
  scrollBehavior
});

router.afterEach((to) => {
  setTimeout(() => {
    const title = to.meta && to.meta.title

    // 如果不需要标题就传空字符串('')，会被替换成\u200e
    if (title) {
      document.title = title
    }

    if (window.ga) {
      window.ga('set', 'location', window.location.href)
      window.ga('send', 'pageview')
    }
  })
})

export default router

function scrollBehavior(to, from, savedPosition) {
  // NOTE: 如果路由切换的动画改变了要进入的页面容器的高度，那么此处需要返回异步，或者在动画里滚动
  if (savedPosition) {
    return savedPosition
  } else {
    return {
      x: 0,
      y: 0
    }
  }
}
