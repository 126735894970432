import request from '@/axios/request'

export default {
  //登录
  login(data = {}) {
    data.from = window.FROM || ''

    return request({
      method: 'POST',
      url: '/api/v1/login',
      data: JSON.stringify(data)
    })
  },
  //sms
  getSms(data = {}) {
    return request({
      method: 'POST',
      url: '/api/v1/send_code',
      data
    })
  },
  //获取所有展示店铺商品
  getGoods({page_size = 500, status = 0, category = 0} = {}) {
    return request({
      method: 'GET',
      url: '/api/v1/activities',
      params: {
        page_size,
        status,
        category
      }
    })
  },
  //获取指定优惠活动详情
  getActivityById(id) {
    return request({
      method: 'GET',
      url: `/api/v1/activities/${id || 1}`
    })
  },
  //获取一个优惠活动的拼团
  //status: 0 进行中 1已成功
  getGroupsByActivityId({status, page_size = 5, activityId}) {
    return request({
      method: 'GET',
      url: `/api/v1/activities/${activityId || 1}/groups`,
      params: {
        page_size,
        status
      }
    })
  },
  //参加拼团
  joinById(id) {
    return request({
      method: 'POST',
      url: `/api/v1/groups/${id || 1}/members`
    })
  },
  //发起一个拼团
  startNewGroup(id) {
    return request({
      method: 'POST',
      url: `/api/v1/activities/${id || 1}/groups`
    })
  },
  //获取一个拼团活动
  getGroupById(id) {
    return request({
      method: 'GET',
      url: `/api/v1/groups/${id}`
    })
  },
  //获取我的拼团
  getMyGroups(id) {
    return request({
      method: 'GET',
      url: `/api/v1/user/${id || 1}/groups`,
      params: {
        page_size: 100
      }
    })
  },
  //拼团成功前10人信息
  getSuccessMembersTop10() {
    return request({
      method: 'GET',
      url: '/api/v1/activities/1/members',
      params: {
        count: 10
      }
    })
  },
  //获取评论
  getComments(id,{page_size = 100}) {
    return request({
      method: 'GET',
      url: `/api/v1/activities/${1}/comments`,
      params: {
        page_size
      }
    })
  },
  //使用免拼
  useFreeCoupon(id) {
    return request({
      method: 'PATCH',
      url: `/api/v1/groups/${id}`
    })
  },
  //用户拼团状态
  getUserGroupStatus(id) {
    return request({
      method: 'GET',
      url: `/api/v1/activities/${id || 1}/user_state`
    })
  },
  //全部分类
  getCategories() {
    return request({
      method: 'GET',
      url: '/api/v1/categories'
    })
  },
  //推荐商品
  getRecommendGoods({page_size = 100, category = 0} = {}) {
    return request({
      method: 'GET',
      url: '/api/v1/rec',
      params: {
        page_size,
        category
      }
    })
  },
  //订阅推送
  subscribeDailyActivity(id) {
    return request({
      method: 'POST',
      url: `/api/v1/activities/${id}/subscribe`
    })
  },
  //添加评论
  addComment(id, data = {}){
    return request({
      method: 'POST',
      url: `/api/v1/admin/activities/${id}/comments`,
      data
    })
  },
  //删除评论
   // 删除评论
   deleteComment(id){
    return request({
      method: 'DELETE',
      url: `/api/v1/admin/activities/${id}/comments`
    })
  },
}