import storage from '@/utils/storage'
import service from '../service'

//自动登录
function autoLogin() {
  return new Promise((resolve) => {
    //存在mods用户数据
    if (window.DATA) {
      service.login({
        data: window.DATA,
        type: 1
      }).then((data) => {
        if (data && data.token) {
          console.log(data)
          //保存登录信息
          storage.set('token', data.token)
          storage.set('userid', data.id)
        }

        resolve(true)
      }).catch(() => {
        resolve(true)
      })
    } else {
      resolve(true)
    }
  })
}

const auto = autoLogin()

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        //判断是否已登录
        $getAuth() {
          return auto.then(() => {
            let result
            let token = storage.get('token')

            if (token) {
              result = Promise.resolve()
            } else {
              result = Promise.reject()
            }

            return result
          })
        },
        //获取登录用户信息
        $getUserInfo() {
          return auto.then(() => {
            return Promise.resolve({
              userid: storage.get('userid')
            })
          })
        }
      }
    })
  }
}