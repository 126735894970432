import host from '../config/host'

const buildUrl = (config) => {
  let url
  url = config.url ? config.url : ''
  const hostname = config.hostname || 'wowsaa'

  if (url.indexOf('http') === -1) {
    config.url = host[hostname] + url;
  }

  return config
}

export default buildUrl
