<template>
  <div>
    <div class="popup-mask" v-if="isShow" @click="onClose"></div>
    <div class="share popup-bottom" :class="{show: isShow, min: !shareInfo && !isShowWhatsAppQr}">
      <div class="close" @click="onClose"></div>

      <template v-if="!isShowWhatsAppQr">
        <template v-if="shareInfo">
          <div class="title">
            Kurang <span>{{shareInfo.less}} orang</span> lagi. Yuk, undang<br/>teman agar anda mendapatkan kupon diskon
          </div>
          <div class="desc">
            Kupon diskon diterima setelah grup terisi {{shareInfo.total}} orang
          </div>
        </template>

        <div class="apps fx-row fx-wrap">
          <div class="w" @click="shareTo('whatsapp')">WhatsAPP</div>
          <div class="f" @click="shareTo('facebook')">Facebook</div>
          <div class="t" @click="shareTo('twitter')">Twitter</div>
          <!--div class="i" @click="shareTo('instagram')">Instagram</div-->
          <div class="q" @click="showQrcode">Kode QR</div>
        </div>
      </template>

      <!-- whatsapp qr -->
      <template v-else>
        <div class="whats-app-qr">
          <canvas id="qrCanvas"></canvas>
        </div>

        <div class="title">
          Pindai Kode QR dengan Fungsi kamera ponsel dan JOIN grup saya untuk mendapatkan kupon diskon
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import shareHelper from '@/utils/shareHelper'
  import QRCode from 'qrcode'
  //filter
  import formatDiscountRate from '../filters/formatDiscountRate'

  export default {
    data() {
      return {
        isShow: false,
        isShowWhatsAppQr: false,
        shareInfo: false,
        shareUrl: ''
      }
    },
    watch: {
      isShow(val) {
        if (!val) {
          this.isShowWhatsAppQr = false
          this.shareInfo = false
        }
      }
    },
    methods: {
      onClose() {
        this.setShow(false)
      },
      setShow(val) {
        if (val) {
          this.isShow = true

          //邀请信息
          if (val.info) {
            this.shareInfo = val.info
          }

          this.shareUrl = val && val.url || location.href
        } else {
          this.isShow = false
        }
      },
      shareTo(target) {
        window.track('wowsaa-groups', target, 'share')
        window.track('wowsaa-groups', `${target}-${this.shareInfo.activityId}`, 'share')
        let title = this.shareInfo && this.shareInfo.title || ''
        let rate = this.shareInfo && this.shareInfo.discount_rate || ''
        rate = rate && formatDiscountRate(rate) || ''

        let url = shareHelper(target, this.shareUrl, `Hai, saya membeli ${title}, beli dengan saya dan harganya serendah RP${rate}, klik tautan di bawah untuk berpartisipasi: `)
        location.href = url
      },
      showQrcode() {
        window.track('wowsaa-groups', `QRCode`, 'share')
        window.track('wowsaa-groups', `QRCode-${this.shareInfo.activityId}`, 'share')

        this.isShowWhatsAppQr = true

        //生成qrcode
        this.$nextTick(() => {
          QRCode.toCanvas(
            document.getElementById('qrCanvas'),
            this.shareUrl,
            //异常
            () => {
            }
          )
        })
      }
    },
    created() {
      this.$bus.$off('share', this.setShow);
      this.$bus.$on('share', this.setShow);
    }
  }
</script>

<style lang="scss" scoped>
  .share {
    height: pxTo(380);
    padding: pxTo(46) 0 pxTo(48-18) 0;

    &.min {
      height: pxTo(380-63);
    }

    .close {
      width: pxTo(16);
      height: pxTo(16);
      position: absolute;
      left: pxTo(16);
      top: pxTo(16);

      @include bg($img-base, 'ic_close.svg');
      background-size: 100% 100%;
    }

    .title {
      text-align: center;
      padding: 0 pxTo(20);

      @extend %ff-rm;
      @include fs-l-w-c(18, 21, 500, $c9);

      > span {
        @include fs-l-w-c(18, 21, 500, $c16);
      }
    }

    .desc {
      text-align: center;
      margin-top: pxTo(9);

      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);
    }

    .apps {
      margin-top: pxTo(14);
      padding: 0 pxTo(42);

      > div {
        width: 33%;
        text-align: center;
        padding-top: pxTo(64+4);
        background-size: pxTo(64) pxTo(64) !important;
        background-position: top center !important;
        margin-bottom: pxTo(18);

        @extend %ff-rr;
        @include fs-l-w-c(14, 16, 400, $c9);

        &.w {
          @include bg($img-base+ 'icon/', 'ic_whatsapp.svg');
        }

        &.f {
          @include bg($img-base+ 'icon/', 'ic_facebook.svg');
        }

        &.t {
          @include bg($img-base+ 'icon/', 'ic_twitter.svg');
        }

        &.i {
          @include bg($img-base+ 'icon/', 'ic_instagram.svg');
        }

        &.q {
          @include bg($img-base+ 'icon/', 'ic_qrcode.svg');
        }
      }
    }


    .whats-app-qr {
      width: pxTo(180);
      height: pxTo(180);
      margin: pxTo(70-46) auto pxTo(36) auto;
      position: relative;

      canvas {
        width: pxTo(180) !important;
        height: pxTo(180) !important;
      }
    }
  }
</style>