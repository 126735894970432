<template>
  <div class="page">
    <keep-alive :include="include">
      <router-view></router-view>
    </keep-alive>

    <popup-login></popup-login>
    <popup-share></popup-share>
    <popup-cry></popup-cry>
    <popup-notify></popup-notify>
    <loading></loading>
  </div>
</template>

<script>
  import PopupLogin from './components/popupLogin'
  import PopupShare from './components/popupShare'
  import PopupCry from './components/popupCry'
  import PopupNotify from './components/popupNotify'
  import Loading from './components/loading'

  export default {
    data() {
      return {
        include: ['groupsHome', 'categoryIndex']
      }
    },
    components: {
      PopupLogin,
      PopupShare,
      PopupCry,
      PopupNotify,
      Loading
    }
  }
</script>

<style lang="scss">
  @import "~@/scss/common.scss";
  @import "~@/scss/flex.scss";

  html, body {
    @extend %ff-rr;

    div, span {
      @extend %ff-rr;
    }

    input {
      width: 100%;
      height: pxTo(28);
      display: block;
      padding: 0 pxTo(10) !important;
      outline: none !important;
      border: 0 !important;
      border-radius: 0 !important;
      border-bottom: 1px solid $c17 !important;

      @extend %ff-rm;
      @include fs-l-w-c(16, 28, 400 $c9);

      &:focus, &.focus {
        outline: none !important;
        border-bottom: 1px solid $c5 !important;
      }
    }

    // a {
    //   color: $c5 !important;
    //   padding: 0 !important;
    //   text-decoration: underline !important;

    //   &:checked, &:visited, &:hover, &:focus {
    //     color: $c5 !important;
    //   }
    // }

    .nav-title {
      width: 70%;
      height: 100%;
      text-align: center;
      margin: 0 auto;

      @extend %ff-rb;
      @include fs-l-w-c(18, 52, 700, $c2);
    }

    .tag {
      display: inline-block;
      width: auto;
      height: pxTo(21);
      padding: 0 pxTo(9) !important;
      border-radius: pxTo(10);
      margin-right: pxTo(10);
      margin-bottom: pxTo(10);

      @extend %ff-rm;
      @include fs-l-w-c(10, 21, 500);

      &.pink {
        background-color: $c7;
        color: $c8;
      }

      &.orange {
        height: pxTo(21);
        background: $bg1;
        padding: 1px !important;

        > div {
          padding: 0 pxTo(10);
          height: pxTo(21-2);
          background: $c2;
          border-radius: pxTo(9);
          color: $c5;
        }
      }

      &.gray {
        background-color: $c15;
        color: $c10;
      }
    }

    .b-t {
      border-top: 1px solid $c17;
    }

    .b-b {
      border-bottom: 1px solid $c17;
    }

    .box {
      margin-top: pxTo(10) !important;
      background-color: $c2;

      .title {
        height: pxTo(36);
        padding: 0 pxTo(16);

        @extend %ff-rm;
        @include fs-l-w-c(14, 16, 500, $c9);

        > div {
          @extend %ff-rm;
          @include fs-l-w-c(14, 16, 500, $c9);

          &.more {
            @include fs-l-w-c(11, 13, 500, $c12);
          }
        }
      }
    }

    .avatar {
      border-radius: 50%;
      overflow: hidden;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      > div {
        width: 100%;
        height: 100%;
        background-color: $c2;
        border-radius: 50%;
        overflow: hidden;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .popup-mask {
      @extend %mask;
      overflow: hidden;
    }

    .pupup-center {
      width: pxTo(320);
      background-color: $c2;
      border-radius: pxTo(16);
      position: relative;
    }

    .popup-bottom {
      width: 100%;
      position: fixed;
      bottom: pxTo(-800);
      padding: pxTo(36) pxTo(40) pxTo(30) pxTo(40);
      background: $c2;
      transition: all 500ms;
      border-radius: pxTo(16) pxTo(16) 0 0;

      @extend %popup-z;

      &.show {
        bottom: 0;
      }
    }

    .btn-rainbow {
      background: $bg1;
      padding: 1px;
      border-radius: pxTo(6);

      > div {
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: $c2;
        border-radius: pxTo(5);
        overflow: hidden;

        @extend %ff-rb;
        @include fs-l-w-c(14, 42-2, 900, $c5);
      }
    }

    .swiper-pagination {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: pxTo(6);

      .swiper-pagination-bullet {
        width: pxTo(31) !important;
        height: pxTo(4) !important;
        border-radius: pxTo(2) !important;
        background-color: $c2 !important;
        opacity: .5 !important;
        margin: 0 pxTo(1) !important;

        &.swiper-pagination-bullet-active {
          opacity: 1 !important;
        }
      }
    }

    .swiper-container {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper-wrapper {
        z-index: 0 !important;

        .swiper-slide {
          z-index: 0 !important;
        }
      }

      .item {
        background-size: 100% auto !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
      }
    }

    .goods-details {
      padding: pxTo(16) 0 pxTo(12) 0;

      h3 {
        padding: 0 pxTo(20);
        margin-bottom: pxTo(10);

        @extend %ff-rm;
        @include fs-l-w-c(12, 14, 500, $c9);

        &.m-t {
          margin-top: pxTo(24-6);
        }
      }

      p {
        padding: 0 pxTo(20);
        margin-bottom: pxTo(6);

        @extend %ff-rr;
        @include fs-l-w-c(12, 14, 400, $c10);
      }

      img {
        width: 100%;
        margin-top: pxTo(10);
      }
    }
  }

  .recommend-goods {
    .goods-list {
      padding: pxTo(12) pxTo(16) pxTo(1) pxTo(16) !important;

      > div:nth-of-type(2n) {
        margin-left: pxTo(11);
      }
    }
  }
</style>