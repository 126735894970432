const routes = {
  'home': '/groups/home',
  'details': '/groups/details',
  'comments': '/groups/comments',
  'invite': '/groups/invite',
  'my': '/groups/my',
  'intro': '/groups/intro',
  'back': 'back',
  'shopify': 'shopify',
  'mine': '/groups/mine',
  'category':'/groups/category'
}

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        gotoPage(name, query) {
          let path = routes[name]

          if (path && path == 'back') {
            if (window.wowsaaHistory.length <= 1) {
              this.$router.replace({path: '/groups/home'})
            } else {
              window.wowsaaHistory.pop()
              this.$router.back()
            }
          } else if (path && path == 'shopify') {
            // 在本窗口打开
            // location.href = query && query.url || ''
            // 在新窗口打开
            window.open(query && query.url || '')
          } else if (path) {
            if (name == 'invite') {
              query.from = 'invite'
            }

            this.$router.push({
              path,
              query
            })
          }
        }
      }
    })
  }
}