import storage from '@/utils/storage'

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $apiErrorHandler(err) {
          this.$hideLoading()

          //登录异常,清除登录状态
          if (err && (err.code == 401 || err.code == 4001)) {
            storage.remove('token')
            storage.remove('userid')

            //提示重新登录
            alert(err && err.message || 'network error')

            //弹出登录页
            this.$bus.$emit('login', {})
          } else {
            alert(err && err.message || 'network error')
          }
        }
      }
    })
  }
}