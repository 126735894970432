<template>
  <div>
    <div class="popup-mask"
         v-if="isShow"
         @click="onClose"></div>
    <div class="login popup-bottom"
         :class="{show:isShow}">
      <template v-if="!isShowCode">
        <div class="title">Verifikasi Nomor Ponsel</div>
        <div class="txt">Nomor Ponsel</div>
        <div class="inputs fx-row m-b-0">
          <div class="ext">
            <input type="tel"
                   v-model="ext"
                   maxlength="5" />
          </div>
          <div class="fx-1">
            <input type="tel"
                   v-model="phone"
                   maxlength="20" />
          </div>
        </div>
        <!-- 用户首次登陆输入的密码即为注册登陆密码 -->
        <div class="txt">Sandi pengguna &nbsp; &nbsp;&nbsp;&nbsp; (Kata sandi yang masuk pertama kali oleh pengguna adalah sandi pendaratan terdaftar ) </div>
        <div class="inputs fx-row m-b-0">
          <!-- <div class="ext">
            <input type="password"
                   v-model="password"
                   maxlength="20" />
          </div> -->
          <div class="fx-1">
            <input type="password"
                   v-model="password"
                   maxlength="20" />
          </div>
        </div>
        <div class="sms"
             :class="{active:checkedWhatsapp}"
             @click="toggleChecked">
          Setuju untuk mengirim kode verifikasi ke nomor WhatsApp anda
        </div>

        <!-- 发送验证码 -->
        <!-- <div class="btn"
             @click="gotoCode">
          {{error.phone ? 'NOMOR TELEPON SALAH, COBA LAGI' : 'KIRIM KODE VERIFIKASI'}}
        </div> -->
        <div class="btn"
             @click="onlogin">
          Login
        </div>
      </template>

      <template v-else-if="isShowCode && !isShowSmsType">
        <div class="title">Verifikasi Nomor Ponsel</div>
        <div class="inputs verifikasi fx-row fx-center">
          <div class="fx-1"
               :class="{error:codes[0].error}">
            <input ref="code0"
                   type="tel"
                   maxlength="1"
                   v-model="codes[0].val"
                   @input="onCode" />
          </div>
          <div class="fx-1"
               :class="{error:codes[1].error}">
            <input ref="code1"
                   type="tel"
                   maxlength="1"
                   v-model="codes[1].val"
                   @input="onCode" />
          </div>
          <div class="fx-1"
               :class="{error:codes[2].error}">
            <input ref="code2"
                   type="tel"
                   maxlength="1"
                   v-model="codes[2].val"
                   @input="onCode" />
          </div>
          <div class="fx-1"
               :class="{error:codes[3].error}">
            <input ref="code3"
                   type="tel"
                   maxlength="1"
                   v-model="codes[3].val"
                   @input="onCode" />
          </div>
          <div class="fx-1"
               :class="{error:codes[4].error}">
            <input ref="code4"
                   type="tel"
                   maxlength="1"
                   v-model="codes[4].val"
                   @input="onCode" />
          </div>
          <div class="fx-1"
               :class="{error:codes[5].error}">
            <input ref="code5"
                   type="tel"
                   maxlength="1"
                   v-model="codes[5].val"
                   @input="onCode" />
          </div>
        </div>

        <div class="btn"
             @click="gotoLogin">
          {{codesError ? 'KODE VERIFIKASI SALAH, COBA LAGI' : 'VERIFIKASI'}}
        </div>
        <!-- 发送验证码 -->
        <a @click="setShowSmsType(true)"
           v-if="canSendSms">KIRIM KODE VERIFIKASI</a>
        <!-- 倒计时 -->
        <div class="count-down"
             v-else-if="showCountDown >= 0">Kirim Ulang {{showCountDown}} detik</div>
      </template>

      <template v-else-if="isShowSmsType">
        <div class="back"
             @click="setShowSmsType(false)"></div>
        <div class="title">Masih belum menerima kode verifikasi</div>
        <div class="desc">
          Kami akan mengirimkan kode verifikasi lagi<br />
          Silahkan pilih metode pengiriman
        </div>
        <div class="btns fx-row">
          <div class="btn-rainbow fx-1"
               @click="delayGetSms(false)">
            <div>VIA SMS</div>
          </div>
          <!-- <div class="btn-whatsapp fx-1" @click="delayGetSms(true)">VIA WhatsApp</div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import storage from '@/utils/storage'
//api
import service from '../service'
import { Toast } from 'vant';
let lock = false
let timer = false

export default {
  data () {
    return {
      isShow: false,
      ext: '+62',
      phone: '',
      password: "",
      callback: false,
      isShowCode: false,
      isShowSmsType: false,
      //120秒重发
      canSendSms: false,
      error: {
        phone: false,
        code: false
      },
      codes: [
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false }
      ],
      checkedWhatsapp: true,
      showCountDown: 60
    }
  },
  computed: {
    codesError () {
      return this.codes[0].error || this.codes[1].error ||
        this.codes[2].error || this.codes[3].error ||
        this.codes[4].error || this.codes[5].error
    }
  },
  methods: {
    onlogin () {
      if (this.password == "" || this.password == null || this.password == undefined) {
        Toast.fail('Tolong atur kata sandi anda ');
      } else {
        let { ext, phone } = getExtPhone(this.ext, this.phone)
        let mobile = `${ext}${phone}`
        service.login({
          mobile,
          password: this.password,
          type: 2
        }).then((data) => {
          if (data && data.token) {
            //保存登录信息
            storage.set('token', data.token)
            storage.set('userid', data.id)
            storage.set('username', data.username)
            storage.set('avatar', data.avatar)
            Toast.success('Berhasil masuk');
            this.$router.push("groups/my")
            this.isShowCode = false
            this.isShow = false
          } else {
            Toast.fail('Log masuk gagal ');
          }
        })
      }

    },
    clean () {
      timer = cleanTimer(timer)
      this.ext = '+62'
      this.phone = ''
      this.callback = false
      this.isShowCode = false
      this.isShowSmsType = false
      this.canSendSms = false
      this.error = {
        phone: false,
        code: false
      }
      this.codes = [
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
        { val: '', error: false },
      ]
      this.checkedWhatsapp = true
      this.showCountDown = 60
    },
    gotoCode () {
      if (lock) {
        return
      }
      lock = true

      let { ext, phone } = getExtPhone(this.ext, this.phone)
      let mobile = `${ext}${phone}`
      if (ext && phone && /^\d{10,25}$/.test(mobile)) {
        this.$showLoading()

        this.getSms().then(() => {
          this.$hideLoading()
          this.isShowCode = true

          setTimeout(() => {
            this.$refs.code0.focus()
          }, 300)
        }).catch(this.$apiErrorHandler).then(() => {
          lock = false
        })
      } else {
        lock = false
        this.error.phone = true
      }
    },
    getSms () {
      let { ext, phone } = getExtPhone(this.ext, this.phone)
      let mobile = `${ext}${phone}`
      let type = this.checkedWhatsapp ? 1 : 0
      return service.getSms({ mobile, type }).then(() => {
        this.showCountDown = 60
        this.canSendSms = false

        timer = cleanTimer(timer)
        timer = setInterval(() => {
          this.showCountDown--

          if (this.showCountDown <= -1) {
            this.canSendSms = true
          }
        }, 1000)
      })
    },
    delayGetSms (checkedWhatsapp = true) {
      if (lock) {
        return
      }
      lock = true

      this.$showLoading()
      this.checkedWhatsapp = checkedWhatsapp

      this.getSms().then(() => {
        this.$hideLoading()
        this.setShowSmsType(false)
      }).catch(this.$apiErrorHandler).then(() => {
        lock = false
      })
    },
    onCode () {
      let len = this.codes.length
      let next = -1

      for (let i = 0; i < len; i++) {
        let item = this.codes[i]

        if (item && item.val !== '' && item.val >= 0) {
          this.$set(this.codes, i, {
            val: item.val,
            error: false
          })
        } else {
          next = i
          break
        }
      }

      if (next >= 0) {
        this.$refs[`code${next}`].focus()
      }
    },
    gotoLogin () {
      let len = this.codes.length
      let error = false
      let codes = ''

      for (let i = 0; i < len; i++) {
        let item = this.codes[i]

        if (item && item.val !== '' && item.val >= 0) {
          codes += item.val
          this.$set(this.codes, i, {
            val: item.val,
            error: false
          })
        } else {
          error = true
          this.$set(this.codes, i, {
            val: '',
            error: true
          })
        }
      }

      if (!error) {
        this.$showLoading()
        this.login(codes)
      }
    },
    login (code = '') {
      if (lock) {
        return
      }
      lock = true

      let { ext, phone } = getExtPhone(this.ext, this.phone)
      let mobile = `${ext}${phone}`

      service.login({
        mobile,
        code
      }).then((data) => {
        if (data && data.token) {
          //保存登录信息
          storage.set('token', data.token)
          storage.set('userid', data.id)
        }
      }).then(() => {
        //登录成功
        //有回调
        if (this.callback) {
          setTimeout(() => {
            this.callback()
            this.callback = false
          }, 300)
        } else {
          this.$bus.$emit('loginSuccess')
        }

        this.onClose()
      }).catch((err) => {
        this.$hideLoading()
        this.$apiErrorHandler(err)
      }).then(() => {
        lock = false
      })
    },
    onClose () {
      this.setShow(false)
    },
    setShow (val) {
      if (val) {
        lock = false

        this.clean()
        this.isShow = true
        this.callback = val && val.callback || false
      } else {
        this.isShow = false
      }
    },
    toggleChecked () {
      this.checkedWhatsapp = !this.checkedWhatsapp
    },
    setShowSmsType (val) {
      this.isShowSmsType = val
    }
  },
  created () {
    this.$bus.$off('login', this.setShow);
    this.$bus.$on('login', this.setShow);
  }
}

function getExtPhone (ext, phone) {
  ext = ext.replace(/\s/g, '').replace(/\+/g, '').replace(/(^0*)/g, '') || 62
  phone = phone.replace(/\s/g, '').replace(/(^0*)/g, '')

  return { ext, phone }
}

function cleanTimer (timer) {
  timer && clearInterval(timer)
  return false
}
</script>

<style lang="scss" scoped>
.login {
  .title {
    text-align: center;
    //height: pxTo(21);

    @extend %ff-rm;
    @include fs-l-w-c(18, 21, 400, $c9);
  }

  .txt {
    margin-top: pxTo(31);
    height: pxTo(13);

    @include fs-l-w-c(11, 13, 500, $c12);
  }

  .inputs {
    margin-top: pxTo(16);
    margin-bottom: pxTo(30);

    &.m-b-0 {
      margin-bottom: 0;
    }

    .ext {
      width: pxTo(50);
      margin-right: pxTo(10);
    }

    &.verifikasi {
      margin-top: pxTo(10);
      margin-bottom: pxTo(20);

      > div {
        width: pxTo(40);
        height: pxTo(48);
        border-radius: pxTo(6);
        overflow: hidden;
        margin-right: pxTo(8);

        &.error {
          input {
            border-color: $c18 !important;
          }
        }

        input {
          text-align: center !important;
          width: 100% !important;
          height: 100% !important;
          padding: 0 !important;
          border-radius: pxTo(6) !important;
          border: 1px solid $c17 !important;
          outline: none !important;

          @extend %ff-rm;
          @include fs-l-w-c(24, 48, 400, $c9);

          &:focus,
          &.focus {
            border-color: $c5 !important;
          }
        }
      }
    }
  }

  .sms {
    margin-top: pxTo(8);
    padding-left: pxTo(16+8);
    margin-bottom: pxTo(19);

    @extend %ff-rm;
    @include fs-l-w-c(11, 13, 500, $c12);
    @include bg($img-base, "un-check.png");
    background-size: pxTo(16) pxTo(16) !important;
    background-position: left center !important;

    &.active {
      @include bg($img-base, "checked.png");
    }
  }

  .btn {
    text-align: center;
    height: pxTo(42);
    background: $bg1;
    border-radius: pxTo(6);

    @extend %ff-rb;
    @include fs-l-w-c(14, 42, 900, $c2);
  }

  a,
  .count-down {
    text-align: center;
    display: block;
    height: pxTo(14);
    margin-top: pxTo(7);

    @extend %ff-rm;
    @include fs-l-w-c(12, 14, 400, $c5);
  }

  .count-down {
    color: $c12;
  }

  .back {
    width: pxTo(24);
    height: pxTo(25);
    position: absolute;
    left: pxTo(16);
    top: pxTo(16);

    @include bg($img-base, "back.png");
    background-size: 100% 100%;
  }

  .desc {
    text-align: center;
    margin-top: pxTo(9);

    @extend %ff-rr;
    @include fs-l-w-c(12, 14, 400, $c10);
  }

  .btns {
    margin-top: pxTo(24);

    .btn-rainbow {
      margin-right: pxTo(8);
      height: pxTo(38);

      > div {
        font-size: pxTo(11);
        line-height: pxTo(36);
      }
    }

    .btn-whatsapp {
      text-align: center;
      height: pxTo(38);
      background: $bg1;
      border-radius: pxTo(6);

      @extend %ff-rb;
      @include fs-l-w-c(11, 38, 900, $c2);
    }
  }
}
</style>